import { useEffect } from "react";
import SearchGrafikOff from "../components/SearchGrafikOff";
import { getplanedPodiys } from "../Axios/searchoff";
import { getHistory } from "../Axios/searchoff";

const Grafik = ({type}) => {

    useEffect(()=>{
        window.scrollTo(0,0);
      }, [])

    return (
        <section className="power-off">
            <div className="container">
                {/* type === 0 - планове (1 - аварійні) */}
                {type === 0 ? 
                    <div className="content" data-aos="fade-up">
                        <h2 className="power-off__title">У цьому розділі вебсайту Ви можете ознайомитись з плановими відключеннями, які пов'язані з виконанням робіт в електромережах Товариства. </h2>
                        <SearchGrafikOff getPodiys = {getplanedPodiys} type = {type}/>
                    </div>
                : 
                    <div className="content" data-aos="fade-up">
                        <h2 className="power-off__title">У цьому розділі веб-сайту Ви можете ознайомитись з архівом аварійних відключень в електромережах Товариства. </h2>
                        <SearchGrafikOff getPodiys = {getHistory} dno = {false} type = {type}/>
                    </div>}
            </div>
        </section> 
    )
}

export default Grafik