import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
// import { sign } from "jwt-encode";
import dayjs from 'dayjs';
import { Link, useParams } from "react-router-dom";
import NoPage from "./NoPage";
import { ToastContainer } from "react-toastify";
import { toast } from 'react-toastify';
import axios from "axios";

const Discrepancy = () => {
    const param = useParams();
    const sign = require('jwt-encode');
    const secret = 'asd-jwt-secret-key-for-discrepancy';
    const [err, setErr] = useState(false);
    const [data, setData] = useState(false);
    const [radioValue, setRadioValue] = useState('')

    useEffect(()=>{
        window.scrollTo(0,0);
        axios({
            method: 'get',
            url: `https://oe-zoe-test.bot.asd.company/api/get-data-for-discrepancy/${param.id}`,
            withCredentials: false,
        }).then(response => {
            try {
                const decoded = jwtDecode(response.data);
                setData(decoded); 
              } catch (e) {
                setErr(true);
            }
        }).catch(()=>{
            setErr(true);
        })

      }, [param.id])

    function handleSubmit(e) {
        e.preventDefault();

        if(radioValue.length > 0) {
           const typeData = {...data, type_dis: radioValue};
           const jwt = sign(typeData, secret);
           axios({
                method: 'get',
                url: `https://oe-zoe-test.bot.asd.company/api/set-discrepancy-res?token=${jwt}`,
                withCredentials: false,
            }).then(() => {
                toast.success(`Дані відправлено в обробку`, {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                setRadioValue('');
            }).catch(()=>{
                setErr(true);
            })
        }
        else {
            toast.error(`Оберіть тип невідповідності`, {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }

    return (
        !err ?
        <section className="discrepancy">
            <div className="container">
                <div className="discrepancy__top">
                    <h4>Шановний клієнте!</h4>
                    <p className="discrepancy__sub">Ви перейшли за посиланням «Повідомити про невідповідність інформації». 
                    За оновленими даними станом на {dayjs(new Date()).format('DD.MM.YYYY HH:mm')}: </p>
                    <p>{data ? data.msg_text: ''}</p>
                </div>

                {/* <div className="discrepancy__mid">
                    <p className="discrepancy__sub">Add new block</p>
                    <p>contentapi</p>
                </div> */}

                <div className="discrepancy__bottom">
                    <p className="discrepancy__sub">Якщо Ви хочете повідомити про невідповідність наданої інформації, оберіть тип невідповідності:</p>
                    {data.s !== undefined &&
                    <form action="submit" onSubmit={handleSubmit}>
                        <div className="radio-inner">
                            <div className="radio">
                                <input id="radio-1" name="radio" type="radio" value={'on'} checked={radioValue === 'on'} onChange={(e)=>{setRadioValue(e.target.value)}}/>
                                <label htmlFor="radio-1" className="radio-label">
                                    {data.s === 'light' && 'У повідомленні вказано, що електроенергія відсутня, але насправді електроенергія зараз є;'}   
                                </label>
                            </div>
                            <div className="radio">
                                <input id="radio-2" name="radio" type="radio" value={'off'} checked={radioValue === 'off'} onChange={(e)=>{setRadioValue(e.target.value)}} />
                                <label  htmlFor="radio-2" className="radio-label">
                                    {data.s === 'light' && 'У повідомленні вказано, що електроенергія є, але насправді електроенергія відсутня;'} 
                                </label>
                            </div>
                    </div>
                    <button className="power-btn">Надіслати</button> 
                    </form>}
                </div>
                <div className="discrepancy__bottom">
                    <p className="discrepancy__sub">Додатково про перерви в електропостачанні:</p>
                    <p className="discrepancy__sub">
                        <Link to={'https://zakarpat.energy/customers/break-in-electricity-supply'}>https://zakarpat.energy/customers/break-in-electricity-supply</Link>
                    </p>
                </div>
            </div>
            <ToastContainer/>
        </section>
        : <NoPage error={'404'}/> 
    )
}

export default Discrepancy