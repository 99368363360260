import { useEffect, useState } from "react"
import { getHistoryPlanned } from "../Axios/searchoff"
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import PaginationLoe from "./PaginationLoe";

const ArhivPlannedList = () => {
    const [history, setHistory] = useState(false);
    const [page, setPage] = useState(1);
    const [totalItems, setTotal] = useState(1);
    const [err, setErr] = useState(false);
    
    useEffect(()=>{
        getHistoryPlanned(1).then(response => {
            const data = response["hydra:member"];
            setTotal(response['hydra:totalItems']);
            setHistory(data);
        }).catch(()=>{
            setErr('Помилка при надсиланні запиту ⚠')
        })
    },[])

    const handleChangePage = (currentPage)=> {
        setPage(currentPage);
        getHistoryPlanned(currentPage).then(response=>{
            const data = response["hydra:member"];
            setHistory(data);
        }).catch(()=>{
            setErr('Помилка при надсиланні запиту ⚠')
        })
    }

    function formatPath(path) {
        // видалити текст який йде до підкреслення _
        const regex = /^.*?_/;
        return path.replace(regex, '');
    }

    return (
      <div className="arhiv-planned">
        <span className="handle-err">{err}</span>
        {history && [...history.sort((first, next)=> next.id - first.id)].map(his => {
            return <p key={his.id}>
                     <Link to={process.env.REACT_APP_SERVER_SEARCH_URL + his.media.contentUrl}>{formatPath(his.media.filePath)} (Розміщено {dayjs(his.dateCreate).format('YYYY-MM-DD')})</Link>
                   </p>
        })}
            <PaginationLoe
                changePage={handleChangePage}
                currentPage={page}
                total={totalItems}
                limit={30}
           /> 
      </div>
    )
  }
  
export default ArhivPlannedList