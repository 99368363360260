import { useEffect } from "react";
import ArhivPlannedList from "../components/ArhivPlannedList";

const GrafikArhiv = () => {

    useEffect(()=>{
        window.scrollTo(0,0);
      }, [])

    return (
        <section className="power-off">
            <div className="container">
                <div className="content" data-aos="fade-up">
                    <h2 className="power-off__title">У цьому розділі веб-сайту Ви можете ознайомитись з архівом планових відключень в електромережах Товариства. </h2>
                    <ArhivPlannedList/>
                </div>
            </div>
        </section> 
    )
}

export default GrafikArhiv